import React from 'react'
import Footer from '../Component/Footer';
import NavBar from '../Component/NavBar';

function Contact() {
  return (
    <div>
      <meta charSet="utf-8" />
      <title>PET SHOP - Pet Shop Website Template</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="keywords" content="Free HTML Templates" />
      <meta name="description" content="Free HTML Templates" />
      {/* Favicon */}
      <link rel="icon" href="img/favicon.ico" />
      {/* Google Web Fonts */}
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link href="https://fonts.googleapis.com/css2?family=Poppins&family=Roboto:wght@700&display=swap" rel="stylesheet" />
      {/* Icon Font Stylesheet */}
      <link href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css" rel="stylesheet" />
      <link href="lib/flaticon/font/flaticon.css" rel="stylesheet" />
      {/* Libraries Stylesheet */}
      <link href="lib/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet" />
      {/* Customized Bootstrap Stylesheet */}
      <link href="css/bootstrap.min.css" rel="stylesheet" />
      {/* Template Stylesheet */}
      <link href="css/style.css" rel="stylesheet" />
      
      {/* Topbar Start */}
      <div className="addresstoptab">
        {/* Topbar End */}
        
      
          
          {/* Contact Start */}
          <div className="container-fluid pt-5">
            <div className="container">
              <div className="border-start border-5 border-dark ps-5 mb-5" style={{ maxWidth: "600px" }}>
                <h6 className="text-dark text-uppercase">Contact Us</h6>
                <h1 className="display-5 text-uppercase mb-0">
                  Please Feel Free To Contact Us
                </h1>
              </div>
              <div className="row g-5">
                <div className="col-lg-7">
                  <form>
                    <div className="row g-3">
                      <div className="col-12">
                        <input type="text" className="form-control bg-light border-0 px-4" placeholder="Your Name" style={{ height: "55px" }} />
                      </div>
                      <div className="col-12">
                        <input type="email" className="form-control bg-light border-0 px-4" placeholder="Your Email" style={{ height: "55px" }} />
                      </div>
                      <div className="col-12">
                        <input type="text" className="form-control bg-light border-0 px-4" placeholder="Subject" style={{ height: "55px" }} />
                      </div>
                      <div className="col-12">
                        <textarea className="form-control bg-light border-0 px-4 py-3" rows="" placeholder="Message" defaultValue="" />
                      </div>
                      <div className="col-12">
                        <button className="btn btn-dark w-100 py-3" type="submit">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-5">
                  <div className="bg-light mb-5 p-5">
                    <div className="d-flex align-items-center mb-2">
                      <i className="bi bi-geo-alt fs-1 text-dark me-3" />
                      <div className="text-start">
                        <h6 className="text-uppercase mb-1">Our Office</h6>
                        <span>
                        Nalapat Complex, Opp LF Convent School Koratty, Thrissur, 680308
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <i className="bi bi-envelope-open fs-1 text-dark me-3" />
                      <div className="text-start">
                        <h6 className="text-uppercase mb-1">Email Us</h6>
                        <span>evelineboutique08@gmail.com</span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-4">
                      <i className="bi bi-phone-vibrate fs-1 text-dark me-3" />
                      <div className="text-start">
                        <h6 className="text-uppercase mb-1">Call Us</h6>
                        <span>9539363694</span>
                      </div>
                    </div>
                    <div>
                      {/* <iframe
                        className="position-relative w-100"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001156.4288297426!2d-78.01371936852176!3d42.72876761954724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1603794290143!5m2!1sen!2sbd"
                        frameBorder="0"
                        style={{ height: "205px", border: 0 }}
                        allowFullScreen
                        aria-hidden="false"
                        tabIndex="0"
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Contact End */}
          
         
          
          {/* Back to Top */}
          <a href="#" className="btn btn-dark py-3 fs-4 back-to-top">
            <i className="bi bi-arrow-up" />
          </a>
          {/* JavaScript Libraries */}
          {/* Template Javascript */}
        
      </div>
    </div>
  );
  
}

export default Contact