import React, { useState } from "react";

import "../css/style.css";
import "../css/bootstrap.min.css";

import NavBar from "../Component/NavBar";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

function PlaceOrder() {
  const location = useLocation();
  const navigate = useNavigate();

  const { product, quantity } = location.state || {};

  // Log the quantity and product details
  console.log("Product:", product);
  console.log("Quantity:", quantity);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const PlaceOrder = async () => {
    console.log(formData.first_name, "jiiii");
    console.log(
      formData.address,
      formData.city,
      formData.state,
      formData.countrys,
      formData.postalCode
    );
    console.log(formData.mobile, "jiiii");
    const fullAddress = `${formData.address}, ${formData.city}, ${formData.state}, ${formData.countrys}, ${formData.postalCode}`;

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5008/boutique/add-order",
        {
          name: formData.first_name,
          address: fullAddress,
          phn_number: formData.mobile,
          product_id: product.p_id,
          quantity: quantity,
        }
      );

      console.log(response.data.message, "Message");
      alert(response.data.message);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "1",
    email: "",
    mobile: "",
    address: "",
    city: "",
    state: "",
    countrys: "",
    postalCode: "",
    save_address: "1",
    billing_address: "",
    ip_address: "1",
    amount: "",
  });
  const allStatesOfIndia = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
    "Ladakh",
    "Jammu and Kashmir",
  ];
  const countries = [
    "India",
    "America",
    "UAE",
    "Australia",
    "Germany",
    "Italy",
    "Ireland",
    "Qatar",
    "Saudi Arabia",
  ];
  const [states, setStates] = useState(allStatesOfIndia);
  const [country, setCountry] = useState(countries);
  return (
    <div>
      <meta charSet="utf-8" />
      <title>PET SHOP - Pet Shop Website Template</title>
      <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      <meta content="Free HTML Templates" name="keywords" />
      <meta content="Free HTML Templates" name="description" />
      {/* Favicon */}
      <link href="img/favicon.ico" rel="icon" />
      {/* Google Web Fonts */}
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins&family=Roboto:wght@700&display=swap"
        rel="stylesheet"
      />
      {/* Icon Font Stylesheet */}
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css"
        rel="stylesheet"
      />
      <link href="lib/flaticon/font/flaticon.css" rel="stylesheet" />
      {/* Libraries Stylesheet */}
      <link
        href="lib/owlcarousel/assets/owl.carousel.min.css"
        rel="stylesheet"
      />
      {/* Customized Bootstrap Stylesheet */}
      <link href="css/bootstrap.min.css" rel="stylesheet" />
      {/* Template Stylesheet */}
      <link href="css/style.css" rel="stylesheet" />

      <div className="container">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            PlaceOrder();
          }}
        >
          <div className="row">
            <div className="col-lg-6 br">
              <>
                <div className="delivery mt-5">
                  <>
                    <h3>Delivery</h3>
                  </>
                </div>

                <div className="row">
                  <>
                    <div className="col-12 mt-3">
                      <input
                        type="text"
                        name="first_name"
                        className="form-control p-3 rounded-3 mt-1"
                        placeholder="Full Name"
                        value={formData.first_name}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    {/* <div className="col-12 col-md-6 mt-3">
                      <input
                        name="billing_address"
                        type="text"
                        placeholder="House name/ building name"
                        className="form-control p-3 rounded-3"
                        value={formData.billing_address}
                        // onChange={handleChange}
                        required
                      />
                    </div> */}
                    <div
                      className="col-12 col-md-6 mt-3"
                      style={{ width: "100%" }}
                    >
                      <input
                        name="address"
                        type="text"
                        placeholder="Address"
                        className="form-control p-3 rounded-3"
                        value={formData.address}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="col-12 col-md-4 mt-3">
                      <input
                        name="city"
                        type="text"
                        placeholder="City"
                        className="form-control p-3 rounded-3"
                        value={formData.city}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-4 mt-3">
                      <select
                        name="state"
                        className="form-control form-select p-3 rounded-3"
                        value={formData.state}
                        onChange={handleChange}
                        required
                      >
                        {allStatesOfIndia?.map((state, index) => (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-md-4 mt-3">
                      <select
                        name="country"
                        className="form-control form-select p-3 rounded-3"
                        value={formData.countrys}
                        onChange={handleChange}
                        required
                      >
                        {countries?.map((countryName, index) => (
                          <option key={index} value={countryName}>
                            {countryName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-md-4 mt-3">
                      <input
                        name="postalCode"
                        type="text"
                        placeholder="Post Office"
                        className="form-control p-3 rounded-3"
                        value={formData.postalCode}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-8 mt-3">
                      <input
                        type="tel"
                        name="mobile"
                        className="form-control p-3 rounded-3 mt-1"
                        placeholder="Phone"
                        value={formData.mobile}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </>
                </div>
                <div className="form-check mt-3">
                  <>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="subscribe"
                      checked={formData.subscribe}
                      onChange={handleChange}
                      id="flexCheckSaveInfo"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckSaveInfo"
                    >
                      Save this information for next time
                    </label>
                  </>
                </div>

                <div>
                  <div
                    className="pay mt-5 mb-4 d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "black",
                      // color: "white",
                      borderRadius: 10,

                      // border: "none",
                    }}
                  >
                    <button
                      type="submit"
                      className="text-center btn fs-4 fw-semibold p-2"
                      style={{
                        color: "white",
                        fontSize: 16,
                        fontFamily: "sans-serif",
                        fontWeight: 500,
                      }}
                    >
                      Place Order
                    </button>
                  </div>
                </div>
              </>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PlaceOrder;
