import React from "react";
import AddressTopTab from "../Component/AddressTopTab";
import NavBar from "../Component/NavBar";

import { Nav, Tab } from "react-bootstrap";
import Aboutimg from "../img/about.png";

function About() {
  return (
    <div>
      <meta charSet="utf-8" />
      <title>PET SHOP - Pet Shop Website Template</title>
      <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      <meta content="Free HTML Templates" name="keywords" />
      <meta content="Free HTML Templates" name="description" />
      {/* Favicon */}
      <link href="img/favicon.ico" rel="icon" />
      {/* Google Web Fonts */}
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins&family=Roboto:wght@700&display=swap"
        rel="stylesheet"
      />
      {/* Icon Font Stylesheet */}
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css"
        rel="stylesheet"
      />
      <link href="lib/flaticon/font/flaticon.css" rel="stylesheet" />
      {/* Libraries Stylesheet */}
      <link
        href="lib/owlcarousel/assets/owl.carousel.min.css"
        rel="stylesheet"
      />
      {/* Customized Bootstrap Stylesheet */}
      <link href="css/bootstrap.min.css" rel="stylesheet" />
      {/* Template Stylesheet */}
      <link href="css/style.css" rel="stylesheet" />
      {/* Topbar Start */}
      <AddressTopTab />

      <div className="container-fluid py-5">
        <div className="container">
          <div className="row col-12">
            <div className="col-md-6  mb-5 mb-lg-0">
              <img className="sideimage" src={Aboutimg} />
            </div>

            <div className="col-md-6 ">
              <Tab.Container defaultActiveKey="pills-1">
                <div className="">
                  <div className="border-start border-5 border-dark ps-5 mb-5">
                    <h6 className="text-dark text-uppercase">About Us</h6>
                    <h1 className="display-5 text-body mb-0">
                      Eveline Boutique has a enormous designs
                    </h1>
                  </div>
                  <h4 className="text-body mb-4">
                    We operate a boutique retailer that specializes in selling
                    sarees and clothing to the public.
                  </h4>
                  <div className="bg-light p-4">
                    <Nav
                      className="nav nav-pills nav-fill justify-content-center mb-3"
                      style={{ width: "100%" }}
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="pills-1">Our Mission</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="pills-2">Our Vision</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="pills-1">
                        <p>
                          At Eveline Boutique , our mission is to celebrate the
                          timeless elegance and rich heritage of sarees by
                          offering an exquisite collection that combines
                          traditional craftsmanship with contemporary design. We
                          are dedicated to providing our customers with
                          high-quality, ethically sourced sarees that reflect
                          diverse cultures and styles. Through exceptional
                          service and personalized experiences, we aim to
                          empower individuals to express their unique beauty and
                          grace.
                        </p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="pills-2">
                        <p>
                          Our vision at Eveline Boutique is to become a globally
                          recognized destination for saree enthusiasts, known
                          for our exceptional quality, innovative designs, and
                          commitment to preserving the art of saree weaving. We
                          aspire to inspire and connect people through the
                          beauty of sarees, fostering a deeper appreciation for
                          cultural heritage and craftsmanship. By promoting
                          sustainable practices and supporting artisans, we aim
                          to create a positive impact on communities and the
                          environment, ensuring the legacy of the saree endures
                          for generations to come.
                        </p>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      {/* Back to Top */}
      <a href="#" className="btn btn-dark py-3 fs-4 back-to-top">
        <i className="bi bi-arrow-up" />
      </a>
      {/* JavaScript Libraries */}
      {/* Template Javascript */}
    </div>
  );
}

export default About;
