import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Screens/Home";
import AddressTopTab from "./Component/AddressTopTab";
import About from "./Screens/About";
import Product from "./Screens/Product";
import Contact from "./Screens/Contact";
import ViewProducts from "./Screens/ViewProducts";
import PlaceOrder from "./Screens/PlaceOrder";
import Layout from "./Component/Layout";
import Services from "./Screens/Services";

function App() {
  return (
    <BrowserRouter>
     <Layout>
      <Routes>
        <Route path="/" element={<Home />}></Route>{" "}
        <Route path="/about" element={<About />}></Route>{" "}
        <Route path="/product" element={<Product/>}></Route>{" "}
        <Route path="/Contact" element={<Contact/>}></Route>{" "}
        <Route path="/viewproduct/:productId" element={<ViewProducts/>}></Route>{" "}
        <Route path="/placeorder" element={<PlaceOrder/>}></Route>{" "}
        <Route path="/service" element={<Services/>}></Route>{" "}
      </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
