import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../img/Logo.png";

function NavBar() {
  return (
    <Navbar
      bg="white"
      expand="lg"
      className="shadow-sm py-3 py-lg-0 px-3 px-lg-0"
    >
      <Container>
        <Link to="/" className="navbar-brand">
          <h1
            className="m-0 letterstyle"
            style={{
              fontFamily: "sans-serif",
              color: "black",
            }}
          >
            <img
              src={Logo}
              alt="Pet Shop Icon"
              className="img-fluid me-3 logostyle"
            />
            EVELINE BOUTIQUE
          </h1>
        </Link>
        <Navbar.Toggle aria-controls="navbarCollapse" />
        <Navbar.Collapse id="navbarCollapse">
          <Nav className="ms-auto py-0">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/about">
              About
            </Nav.Link>
            <NavDropdown title="Services" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/service">
                Designing & Stitching
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/service">
                Hand Embroidery
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/product">
              Product
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link
              as={Link}
              to="/contact"
              className="nav-contact text-white contact d-flex"
              style={{ backgroundColor: "black" }}
            >
              Contact <i className="bi bi-arrow-right" />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
