import React, { useEffect, useState } from "react";
import Noproduct from "../img/noproduct.png";
import { Link } from "react-router-dom";
import axios from "axios";

function Productlisting() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5008/boutique/list-product"
      );
      setProducts(response.data.productlist || []);
    } catch (error) {
      console.error("Error fetching products:", error);
      // Handle error state or show user feedback
    }
  };

  // Function to chunk products into arrays of size 'chunkSize'
  const chunkArray = (array, chunkSize) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunkedArray.push(array.slice(i, i + chunkSize));
    }
    return chunkedArray;
  };

  // Split products into chunks of 2 for mobile views
  const chunkedProducts = chunkArray(products, 2);

  const renderProductRow = (productRow) => {
    return (
      <div className="row container mb-4">
        {productRow?.map((product) => (
          <div key={product.p_id} className="col-lg-6 mb-4">
            <Link
              to={`/viewproduct/${product.p_id}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="d-flex flex-column align-items-center">
                <img
                  className="card-img-top"
                  src={
                    product.p_image
                      ? `https://lunarsenterprises.com:5008/${product.p_image}`
                      : Noproduct
                  }
                  alt={product.p_name}
                  style={{
                    width: "150px",
                    height: "150px",
                    objectFit: "contain",
                    // borderRadius: "10px",
                    marginBottom: "10px",
                  }}
                />
                <div className="text-center">
                  <h5 className="card-title">{product.p_name}</h5>
                  <div className="selected-product-price">
                    <div
                      className="original-price"
                      style={{ textDecoration: "line-through", color: "gray" }}
                    >
                      <span>Rs.</span>
                      {product.price}
                    </div>
                    <div
                      className="discounted-price"
                      style={{ color: "black", marginLeft: "10px" }}
                    >
                      <span>Rs.</span>
                      {product.dicount_price}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="container py-5">
      <div
        className="border-start border-5 border-primary ps-3 mb-5"
        style={{ maxWidth: "600px" }}
      >
        <h6 className="text-primary text-uppercase">Products</h6>
        <h1 className="display-5 text-body mb-0">
          Best Products for your Fashion
        </h1>
      </div>
      <div className="row gridstyle">
        {products?.map((product) => (
          <div key={product.p_id} className="col-6 mb-4">
            <Link to={`/viewproduct/${product.p_id}`} className="product-card">
              <img
                className="productimagestyle bounce"
                src={
                  product.p_image
                    ? `https://lunarsenterprises.com:5008/${product.p_image}`
                    : Noproduct
                }
                alt={product.p_name}
              />
              <div className="product-info mt-2">
                <h5 className="card-title">{product.p_name}</h5>
                <div className="selected-product-price">
                <div className="original-price" style={{ textDecoration: "line-through", color: "gray" }}>
                  <span>Rs.</span>
                  {product.price}
                </div>
                <div className="discounted-price" style={{ color: "black", marginLeft: "10px" }}>
                  <span>Offer Price.</span>
                  {product.dicount_price}
                </div>
              </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Productlisting;
