import React, { useEffect, useState } from "react";
import "../css/style.css";
import "../css/bootstrap.min.css";
import AddressTopTab from "../Component/AddressTopTab";
import Footer from "../Component/Footer";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import Hero from "../img/hero.jpg";
import Offer from "../img/offer.jpg";
import Noproduct from "../img/noproduct.png";
import { Link } from "react-router-dom";
import axios from "axios";

const Home = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // State to manage loading
  const [list, setList] = useState([]);
  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5008/boutique/list-product"
      );
      setProducts(response.data.productlist);
    } catch (error) {
      console.error("Error fetching products:", error);
      // Handle error state or show user feedback
    }
  };
  useEffect(() => {
    fetchBannerList();
  }, []);

  const fetchBannerList = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5008/boutique/list-banner",
        {},
        {
          headers: {
            user_id: "1",
          },
        }
      );

      setList(response.data.list);
      console.log(response.data.list, "productdatalist");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])
  

  return (
    <div>
      <meta charSet="utf-8" />
      <title>PET SHOP - Pet Shop Website Template</title>
      <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      <meta content="Free HTML Templates" name="keywords" />
      <meta content="Free HTML Templates" name="description" />
      {/* Favicon */}
      <link href="img/favicon.ico" rel="icon" />
      {/* Google Web Fonts */}
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins&family=Roboto:wght@700&display=swap"
        rel="stylesheet"
      />
      {/* Icon Font Stylesheet */}
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css"
        rel="stylesheet"
      />
      <link href="lib/flaticon/font/flaticon.css" rel="stylesheet" />
      {/* Libraries Stylesheet */}
      <link
        href="lib/owlcarousel/assets/owl.carousel.min.css"
        rel="stylesheet"
      />
      {/* Customized Bootstrap Stylesheet */}
      <link href="css/bootstrap.min.css" rel="stylesheet" />
      {/* Template Stylesheet */}
      <link href="css/style.css" rel="stylesheet" />
      {/* Topbar Start */}
      <AddressTopTab />

      {/* Hero Start */}
      <Splide
  options={{
    type: "fade",
    perPage: 1,
    autoplay: true,
    pauseOnHover: true,
    arrows: true,
    gap: "1rem",
    breakpoints: {
      992: { perPage: 1 },
      768: { perPage: 1 },
      576: { perPage: 1 },
    },
  }}
  className="splide small-carousel "
>
  {list?.map((banner) => (
    <SplideSlide key={banner.b_id}>
      <img
        src={
          banner.b_image
            ? `https://lunarsenterprises.com:5008/${banner.b_image}`
            : Noproduct
        }
        className="bannerstyle"
        alt={banner.b_name || "Banner image"}
      />
    </SplideSlide>
  ))}
</Splide>

      {/* Hero End */}

      <div className="container py-5">
        <div
          className="border-start border-5 border-primary ps-3 mb-5"
          style={{ maxWidth: "600px" }}
        >
          <h6 className="text-primary text-uppercase">Products</h6>
          <h1 className="display-5 text-body mb-0">
            Best Products for your Fashion
          </h1>
        </div>
      <div className="row gridstyle">
  {products?.slice(0, 4)?.map((product) => (
    <div key={product.p_id} className=" testcolor col-6 mb-4">
      <Link to={`/viewproduct/${product.p_id}`} className="product-card">
        <img
          className="productimagestyle bounce"
          src={
            product.p_image
              ? `https://lunarsenterprises.com:5008/${product.p_image}`
              : Noproduct
          }
          alt={product.p_name}
        />
        <div className="product-info mt-2">
          <h5 className="card-title">{product.p_name}</h5>
          <div className="selected-product-price">
                <div className="original-price" style={{ textDecoration: "line-through", color: "gray" }}>
                  <span>Rs.</span>
                  {product.price}
                </div>
                <div className="discounted-price" style={{ color: "black", marginLeft: "10px" }}>
                  <span>Offer Price.</span>
                  {product.dicount_price}
                </div>
              </div>
        </div>
      </Link>
    </div>
  ))}
  <div className="col-12 text-center">
    <a href="/product">
      <button className="view-more-button">View More</button>
    </a>
  </div>
</div>

      </div>

      <Splide
        options={{
          type: "fade",
          perPage: 1,
          autoplay: true,
          pauseOnHover: true,
          arrows: true,
          gap: "1rem",
          breakpoints: {
            992: {
              perPage: 1,
            },
            768: {
              perPage: 1,
            },
            576: {
              perPage: 1,
            },
          },
        }}
        className="splide small-carousel"
      >
        <SplideSlide>
          <img src={Offer} alt=""  className="bannerstyle"/>
        </SplideSlide>
        <SplideSlide>
          <img src={Hero} alt="" className="bannerstyle" />
        </SplideSlide>
      </Splide>
      {/* Video Modal Start */}
      <div
        className="modal fade"
        id="videoModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Youtube Video
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              {/* 16:9 aspect ratio */}
              <div className="ratio ratio-16x9">
                <iframe
                  className="embed-responsive-item"
                  src
                  id="video"
                  allowFullScreen
                  allowscriptaccess="always"
                  allow="autoplay"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <a href="#" className="btn btn-dark py-3 fs-4 back-to-top">
        <i className="bi bi-arrow-up" />
      </a>
    </div>
  );
};

export default Home;
