// Layout.js
import React from 'react';
import Footer from '../Component/Footer';
import NavBar from './NavBar';


function Layout({ children }) {
  return (
    <div>
      <NavBar/>
      {children}
      <Footer/>
  
    </div>
  );
}

export default Layout;
