import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/fontawesome-free-solid";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "../css/Selected.scss";
import AddressTopTab from "../Component/AddressTopTab";
import Footer from "../Component/Footer";
import Noproduct from "../img/noproduct.png";

function ViewProducts() {
  const [count, setCount] = useState(1);
  const [selectedImage, setSelectedImage] = useState(""); // State for selected image
  const navigate = useNavigate();
  const { productId } = useParams();
  const [productsview, setProductsview] = useState([]);
  const [stockMessage, setStockMessage] = useState(""); // State for stock message

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:5008/boutique/view-product",
          { product_id: productId }
        );
        setProductsview(response.data.productlist);
        console.log(response.data, "viewproductdata");
      } catch (error) {
        console.log(error);
      }
    };

    fetchProducts();

    // Scroll to top after products are fetched
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [productId]);

  const Increment = (product) => {
    if (count < product.p_stock) {
      setCount(count + 1);
      setStockMessage(""); // Clear the message if stock is sufficient
    } else {
      setStockMessage(`Only ${product.p_stock} left`); // Show stock warning if trying to exceed stock
    }
  };

  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
      setStockMessage(""); // Clear the message when decrementing
    }
  };

  const handleBuyNowClick = (product) => {
    navigate("/placeorder", { state: { product, quantity: count } });
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <>
      <div>
        <AddressTopTab />
      </div>

      <div className="container product-display">
        {productsview?.length === 0 ? (
          <p>Loading...</p>
        ) : (
          productsview?.map((product, idx) => (
            <div className="selected-product" key={idx}>
              <div className="product-images">
                <div className="section-images">
                  <img
                    className="sec-imageview"
                    src={
                      selectedImage
                        ? `https://lunarsenterprises.com:5008/${selectedImage}`
                        : product.p_image
                        ? `https://lunarsenterprises.com:5008/${product.p_image}`
                        : Noproduct // Use Noproduct as default image
                    }
                    alt={product.p_name}
                    style={{
                      maxWidth: "80%",
                      height: "80%",
                    }}
                  />
                </div>
                <div className="thumbnail-images">
                  {product.p_image1 && (
                    <img
                      className="thumbnail-image"
                      src={`https://lunarsenterprises.com:5008/${product.p_image1}`}
                      alt="Thumbnail 1"
                      onClick={() => handleImageClick(product.p_image1)}
                      style={{
                        cursor: "pointer",
                        width: "100px",
                        height: "100px",
                      }}
                    />
                  )}
                  {product.p_image2 && (
                    <img
                      className="thumbnail-image"
                      src={`https://lunarsenterprises.com:5008/${product.p_image2}`}
                      alt="Thumbnail 2"
                      onClick={() => handleImageClick(product.p_image2)}
                      style={{
                        cursor: "pointer",
                        width: "100px",
                        height: "100px",
                        marginLeft: 10,
                      }}
                    />
                  )}
                  {product.p_image3 && (
                    <img
                      className="thumbnail-image"
                      src={`https://lunarsenterprises.com:5008/${product.p_image3}`}
                      alt="Thumbnail 3"
                      onClick={() => handleImageClick(product.p_image3)}
                      style={{
                        cursor: "pointer",
                        width: "100px",
                        height: "100px",
                        marginLeft: 10,
                      }}
                    />
                  )}
                  {product.p_image4 && (
                    <img
                      className="thumbnail-image"
                      src={`https://lunarsenterprises.com:5008/${product.p_image4}`}
                      alt="Thumbnail 4"
                      onClick={() => handleImageClick(product.p_image4)}
                      style={{
                        cursor: "pointer",
                        width: "100px",
                        height: "100px",
                        marginLeft: 10,
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="product-details">
                <h1>{product.p_name}</h1>
                <div className="selected-product-price">
                  <div
                    className="original-price"
                    style={{
                      textDecoration: "line-through",
                      color: "gray",
                      marginTop: 10,
                    }}
                  >
                    <span>Rs.</span>
                    {product.price}
                  </div>
                  <div
                    className="discounted-price"
                    style={{ color: "black", marginTop: 10 }}
                  >
                    <span>Offer Rs.</span>
                    {product.dicount_price}
                  </div>
                </div>

                {product.p_stock === 0 ? (
                  <p style={{ color: "red", marginTop: 10 }}>Stock Out</p>
                ) : (
                  <>
                    <p style={{ marginBottom: "5px", marginTop: 10 }}>Quantity</p>
                    <div className="counting-box">
                      <button onClick={decrement}>
                        <FontAwesomeIcon icon={faMinus} />
                      </button>
                      <div className="count">{count}</div>
                      <button onClick={() => Increment(product)}>
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    </div>
                    {stockMessage && (
                      <p style={{ color: "red", marginTop: 10 }}>{stockMessage}</p>
                    )}
                    <p style={{ color: "grey", marginTop: 10 }}>More than 10000 products sold out till now</p>
                    <div className="selected-product-details">
                      <h6>Name: {product.p_name}</h6>
                      <h5>Description:</h5>
                      <h6 style={{ marginTop: 10 }}>{product.p_description}</h6>
                      <h6 style={{ marginTop: 10 }}>Quantity: {count}</h6>
                    </div>
                    <div className="add-buy-buttons">
                      <button
                        onClick={() => handleBuyNowClick(product)}
                      >
                        Buy it now
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          ))
        )}
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default ViewProducts;
