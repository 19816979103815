import React from "react";
import Whatsapp from "../img/whatsapp.png";

const AddressTopTab = () => {
  return (
    <div className="container-fluid border-bottom d-none d-lg-block">
      <div className="row gx-0">
        <div className="col-lg-4 text-center py-2">
          {/* CALL US START */}
          <a
            href="tel:+91 9539363694"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className="d-inline-flex align-items-center">
              <i className="bi bi-phone-vibrate fs-1 text-dark me-3" />
              <div className="text-start">
                <h6 className="text-uppercase mb-1">Call Us</h6>
                <span>9539363694</span>
              </div>
            </div>
          </a>
          {/* CALL US END */}
        </div>
        <div className="col-lg-4 text-center border-start border-end py-2">
          {/* Email start */}
          <a
            href="mailto:evelineboutique08@gmail.com"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className="d-inline-flex align-items-center">
              <i className="bi bi-envelope-open fs-1 text-dark me-3" />
              <div className="text-start">
                <h6 className="text-uppercase mb-1">Email Us</h6>
                <span>evelineboutique08@gmail.com</span>
              </div>
            </div>
          </a>

          {/* Email End */}
        </div>
        <div className="col-lg-4 text-center py-2">
          {/* Our Office */}

          <div className="d-inline-flex align-items-center">
            <a
              href="https://wa.me/9539363694"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "inline-flex", alignItems: "center" }}
            >
              <img
                src={Whatsapp}
                alt="Geo Icon"
                className="fs-1 text-dark me-3"
                style={{ width: "1em", height: "1em", marginTop: 10 }}
              />
            </a>
            <div className="text-start">
              <h6 className="text-uppercase mb-1" style={{ marginTop: 12 }}>
                Whatsapp
              </h6>

              <span>9539363694</span>
            </div>
          </div>
          {/* Our Office End */}
        </div>
      </div>
    </div>
  );
};

export default AddressTopTab;
