import React from "react";

function Footer() {
  return (
    <div>
      <div className="container-fluid bg-light mt-5 py-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <h5 className="text-uppercase border-start border-5 border-dark ps-3 mb-4">
                Get In Touch
              </h5>
              {/* <p className="mb-4">Pawsome Pet Adventures Await!</p> */}
              <p className="mb-2">
                <i className="bi bi-geo-alt text-dark me-2" />
                Nalapat Complex, Opp LF Convent School Koratty, Thrissur, 680308
              </p>
              <a
                href="mailto:evelineboutique08@gmail.com"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <p className="mb-2">
                  <i className="bi bi-envelope-open text-dark me-2" />
                  evelineboutique08@gmail.com
                </p>
              </a>
              <a
                href="tel:+91 9539363694"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <p className="mb-0">
                  <i className="bi bi-telephone text-dark me-2" />
                  9539363694
                </p>
              </a>
            </div>
            <div className="col-lg-3 col-md-6">
              <h5 className="text-uppercase border-start border-5 border-dark ps-3 mb-4">
                Quick Links
              </h5>
              <div className="d-flex flex-column justify-content-start">
                <a className="text-body mb-2" href="/">
                  <i className="bi bi-arrow-right text-dark me-2" />
                  Home
                </a>
                <a className="text-body mb-2" href="/about">
                  <i className="bi bi-arrow-right text-dark me-2" />
                  About Us
                </a>
                <a className="text-body mb-2" href="/service">
                  <i className="bi bi-arrow-right text-dark me-2" />
                  Our Services
                </a>
                <a className="text-body mb-2" href="#">
                  <i className="bi bi-arrow-right text-dark me-2" />
                  Meet The Team
                </a>
                <a className="text-body mb-2" href="#">
                  <i className="bi bi-arrow-right text-dark me-2" />
                  Latest Blog
                </a>
                <a className="text-body" href="/Contact">
                  <i className="bi bi-arrow-right text-dark me-2" />
                  Contact Us
                </a>
              </div>
            </div>
            {/* <div class="col-lg-3 col-md-6">
                  <h5 class="text-uppercase border-start border-5 border-primary ps-3 mb-4">Popular Links</h5>
                  <div class="d-flex flex-column justify-content-start">
                      <a class="text-body mb-2" href="#"><i class="bi bi-arrow-right text-primary me-2"></i>Home</a>
                      <a class="text-body mb-2" href="#"><i class="bi bi-arrow-right text-primary me-2"></i>About Us</a>
                      <a class="text-body mb-2" href="#"><i class="bi bi-arrow-right text-primary me-2"></i>Our Services</a>
                      <a class="text-body mb-2" href="#"><i class="bi bi-arrow-right text-primary me-2"></i>Meet The Team</a>
                      <a class="text-body mb-2" href="#"><i class="bi bi-arrow-right text-primary me-2"></i>Latest Blog</a>
                      <a class="text-body" href="#"><i class="bi bi-arrow-right text-primary me-2"></i>Contact Us</a>
                  </div>
              </div> */}
            <div className="col-lg-3 col-md-6">
              <h5 className="text-uppercase border-start border-5 border-dark ps-3 mb-4">
                Newsletter
              </h5>
              <form action>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control p-3"
                    placeholder="Your Email"
                  />
                  <button className="btn btn-dark">Sign Up</button>
                </div>
              </form>
              <h6 className="text-uppercase mt-4 mb-3">Follow Us</h6>
              <div className="d-flex">
                <a className="btn btn-outline-dark btn-square me-2" href="#">
                  <i className="bi bi-twitter" />
                </a>
                <a
                  className="btn btn-outline-dark btn-square me-2"
                  href="https://www.facebook.com/EvelineBoutique123?mibextid=ZbWKwL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bi bi-facebook" />
                </a>

                <a className="btn btn-outline-dark btn-square me-2" href="#">
                  <i className="bi bi-linkedin" />
                </a>
                <a
                  className="btn btn-outline-dark btn-square"
                  href="https://www.instagram.com/eveline_boutique?igsh=N3lvdzMyMjkyM25n"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bi bi-instagram" />
                </a>
              </div>
            </div>
            <div className="col-12 text-center text-body">
              <a className="text-body" href>
                Terms &amp; Conditions
              </a>
              <span className="mx-1">|</span>
              <a className="text-body" href>
                Privacy Policy
              </a>
              <span className="mx-1">|</span>
              <a className="text-body" href>
                Customer Support
              </a>
              <span className="mx-1">|</span>
              <a className="text-body" href>
                Payments
              </a>
              <span className="mx-1">|</span>
              <a className="text-body" href>
                Help
              </a>
              <span className="mx-1">|</span>
              <a className="text-body" href>
                FAQs
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid  text-white-50 py-4">
        <div className="container">
          <div className="row g-5">
            <div className="col-md-6 text-center text-md-start">
              <p className="mb-md-0 text-body">
                ©{" "}
                <a className="text-black" style={{ color: "black" }} href="#">
                  Your Site Name
                </a>
                . All Rights Reserved.
              </p>
            </div>
            <div className="col-md-6 text-center text-md-end">
              <p className="mb-0 text-body">
                Designed by{" "}
                <a
                  className="text-body"
                  style={{ color: "black" }}
                  href="https://htmlcodex.com"
                >
                  Lunar Entreprises
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
